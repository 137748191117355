import Alpine from 'alpinejs';

// Named export
export const scrollMenu = () => {
  /**
 * scrollToReveal - Scroll to reveal the header using AlpineJS
 * returns data to Alpine in the form of a JS object called "scrollToReveal"
 * sticky
 * lastPos : used internally to keep track of the last position
 * scroll(): a function that is called on scroll and returns sticky, lastPos, and pageStart
 */

Alpine.data('scrollToReveal', () => ({
  sticky: false,
  lastPos: window.scrollY + 0,
  pageStart: true,
  scroll() {
    this.sticky =
      window.scrollY > this.$refs.navbar.offsetHeight &&
      this.lastPos < window.scrollY;
    this.lastPos = window.scrollY;
    this.pageStart =
      window.scrollY < this.$refs.navbar.offsetHeight &&
      this.lastPos > window.scrollY;
  },
}));
};

// Next is the functionality to highlight the current inner page section

// Confirm we have a main element to work with
// before creating the intersection observer for
// the inner section navigation
if (document.getElementsByTagName('main')[0]) {
  // get section elements in the main element
  // and create an array of their ids
  const sections = document
    .getElementsByTagName('main')[0]
    .querySelectorAll('section');

  // get the internal-nav element
  const navbar = document.getElementById('internal-nav');

  if (navbar && sections.length > 0) {
    const margins = '-100px 0px -80% 0px';

    const ioOptions = {
      root: null,
      threshold: 0,
      rootMargin: margins,
    };

    // add active class to the first navbar link
    navbar.querySelectorAll('li')[0].classList.add('active');

    const observer = new IntersectionObserver((sections) => {
      sections.forEach((section) => {
        if (section.isIntersecting) {

          let selected = section.target.dataset.index;
          // console.log('intersecting', section.target.dataset.index);
          // console.log('navbar', navbar.children);
          Array.from(navbar.children).forEach((element, index) => {
            // console.log('nav', index)
            if (index == selected) {
              element.classList.add('active');
            } else {
              element.classList.remove('active');
            }
          });

        } else {
          // console.log('not intersecting', section.target.id)
        }
      });
    }, ioOptions);

    sections.forEach((section, index) => {
      // Add a data-index attribute to each section
      // this will be used to identify the active
      // section in the navbar
      section.dataset.index = index;
      observer.observe(section);
    });
  }
}
