import Alpine from 'alpinejs';

export const scrollToPositionlInnerNav = () => {
  /**
   * scrollToRevealInnerNav - Scroll to move the left hand inner nav element using AlpineJS
   * for reference see the scroll-to-reveal-menu.js file which has similar functionality
   * but for controling the visibility of the global nav
   *
   * returns data to Alpine in the form of a JS object called "scrollToPositionLeftNav"
   * sticky
   * lastPos : used internally to keep track of the last position
   * scroll(): a function that is called on scroll and returns sticky, lastPos, and pageStart
   */
  Alpine.data('scrollToPosition', () => ({
    sticky: false,
    lastPos: window.scrollY + 0,
    pageStart: true,
    scroll() {
      this.sticky =
        window.scrollY > 75 &&
        this.lastPos < window.scrollY;
      this.lastPos = window.scrollY;
      this.pageStart =
        window.scrollY < 75 &&
        this.lastPos > window.scrollY;
      },
  }));




};
