import Alpine from 'alpinejs';

/**
 * Mobile Menu
 *
 * Toggles menu on mobile devices starting a the lg breakpoint
 * which in Tailwind is defined at min-width: 992px
 */

// helper function to test breakpoint for mobile view
const _isMobileView = function (breakpoint) {
  return !window.matchMedia(`(min-width: ${breakpoint}px)`).matches;
};

// Named export
// @param {Integer} breakpoint
export const mobileMenu = (breakpoint = 992) => {
  // Mobile Menu Control to pass into Alpine JS x-data
  Alpine.data('mobileMenuControl', () => ({
    globalNavVisible: _isMobileView(breakpoint)
      ? false // false is the default
      : true,
    userHasTakenAction: false,
    userSetState: null,
    toggleMenu() {
      this.globalNavVisible = !this.globalNavVisible;
      this.userSetState = this.globalNavVisible;
      this.userHasTakenAction = true;
    },
    resizeHandler() {
      if (_isMobileView(breakpoint)) {
        if (this.userHasTakenAction) {
          this.globalNavVisible = this.userSetState;
        } else {
          this.globalNavVisible = false;
        }
      } else {
        this.globalNavVisible = true;
      }
    },
  }));
};
