import Alpine from 'alpinejs';

// Alpine.js needs to be accessible from the window object
window.Alpine = Alpine;

const breakpoint = 992;

// Functionality is broken up into
// separate files for each feature
// for easier updating and maintenance

import { mobileMenu } from "./mobile-menu.js";
mobileMenu(breakpoint);

import { dropDownNav } from "./desktop-dropdown-nav.js";
dropDownNav(breakpoint);

import {scrollMenu} from "./scroll-to-reveal-menu";
scrollMenu();

import { externalLinks } from "./external-links.js";
externalLinks();

import {scrollToPositionlInnerNav} from "./scroll-to-reveal-inner-nav.js";
scrollToPositionlInnerNav();

// import { marketoFormStyleRemoval } from "./marketo-form-style-removal.js";
// marketoFormStyleRemoval();


// a 'magic' Alpine.js function that will copy text to the clipboard
// https://alpinejs.dev/advanced/extending#magic-functions
// It needs to be in an Alpine element, defined by an x-data attribute
Alpine.magic('clipboard', () => subject => {
  navigator.clipboard.writeText(subject)
})


Alpine.start();
