import Alpine from 'alpinejs';

/**
 * Mobile Menu
 *
 * Toggles menu on mobile devices starting a the lg breakpoint
 * which in Tailwind is defined at min-width: 992px
 */

// helper function to test breakpoint for mobile view
const _isMobileView = function (breakpoint) {
  return !window.matchMedia(`(min-width: ${breakpoint}px)`).matches;
};

// Named export
// @param {Integer} breakpoint
export const dropDownNav = (breakpoint = 992) => {
  // Dropdown Menu
  Alpine.data('dropDownControl', () => ({
    menuOpen: false,
    openMenu(override = false) {

      if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
        this.menuOpen = true;
      } else if (_isMobileView(breakpoint) && (override === true)) {
        // console.log('override', override);
        this.menuOpen = true;
      }
    },
    closeMenu() {
      if (!_isMobileView(breakpoint)) {
        this.menuOpen = false;
      }
    },
    mobileClickHandler(e) {
      // The mobile menu is wrapped in a span tag because we
      // use a border instead of an actual underline.
      // This means we need to navigate to the parent element and then
      // look for the next sibling element for the presense of a sub menu
      // console.log('mobileClickHandler', e.target.parentNode.nextElementSibling.classList.contains('submenu'));

      // if the user clicks on the mobile menu,
      // we want to toggle the submenu if it exists
      if (_isMobileView(breakpoint)) {

        e.preventDefault();
        // check is next node had class of 'submenu'
        if (
          // e.target.parent.nextElementSibling.classList.contains('submenu')
          e.target.parentNode.nextElementSibling.classList.contains('submenu')
        ) {
          this.menuOpen = !this.menuOpen;


        }
      }
    },
  }));
};
